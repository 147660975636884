import type { RouteConfig } from 'vue-router'
import type { CMSPageId } from '/~/types/cms/page'
import { RewardsExtension } from '/~/core/extension'
import mdl from '/~/core/mdl'
import { useCommunityHub } from '/~/extensions/community-hub/composables'
import modals from './modals'

const { initCommunityHub, label, moduleName, syncState } = useCommunityHub()

mdl.register(modals)

class CommunityHubExtension extends RewardsExtension {
  get isMarketplace() {
    return true
  }

  async setState() {
    return initCommunityHub()
  }

  setRoutes() {
    return [
      {
        path: `/${moduleName}`,
        component: () => import('./community-hub.vue'),
        meta: {
          title: label.value,
        },
        children: [
          {
            path: 'retailer/:id/',
            name: `${moduleName}-retailer`,
            props: true,
            meta: {
              title: `${label.value} Retailer`,
              theme: 'white',
              hideMenu: true,
            },
            component: () =>
              import(
                './templates/default/views/retailer/community-hub-retailer.vue'
              ),
          },
          {
            path: 'retailer/:id/redirect',
            name: `${moduleName}-redirect`,
            props: true,
            meta: {
              title: `${label.value} Retailer Redirect`,
              hideMenu: true,
            },
            component: () =>
              import(
                './templates/default/views/redirect/community-hub-offers-redirect.vue'
              ),
          },
          {
            path: ':category?',
            name: moduleName,
            props: true,
            meta: {
              title: label.value,
              page: 'community-hub' as CMSPageId,
            },
            component: () =>
              import(
                `./templates/${this.templateName}/views/catalog/community-hub-catalog.vue`
              ),
            beforeEnter(to, from, next) {
              syncState({ to })
              next()
            },
          },
        ] as RouteConfig[],
      },
    ]
  }
}

export default CommunityHubExtension
